/* This only loads the Facebook SDK - it'll need to be "init'ed" on the window.fbAsyncInit event
 * We do this in main.js
 */
var call_facebook = function () {
      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "//connect.facebook.net/en_US/all.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));
};

var call_twitter = function() {
	window.twttr = (function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0],
		t = window.twttr || {};
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
	  	js.id = id;
	  	js.src = "https://platform.twitter.com/widgets.js";
	  	fjs.parentNode.insertBefore(js, fjs);

	  	t._e = [];
	  	t.ready = function(f) {
	    	t._e.push(f);
	  	};

	  	return t;
	}(document, "script", "twitter-wjs"));
};

var call_google = function() {
	$.getScript('//apis.google.com/js/platform.js');
};

/* I'm not sure why this needs the second attempt to load. I took what David did and mashed it up a bit */
var call_whatsapp = function() {
	/* $.getScript('//cdn.jsdelivr.net/whatsapp-sharing/1.2.3/whatsapp-button.js',
		function (data, textStatus, jqxhr) {
			if(typeof wabtn4fg==="undefined") {
				wabtn4fg=1;
				h=document.getElementsByTagName("head")[0];
				s=document.createElement("script");
				s.type="text/javascript";
				s.src="//cdn.jsdelivr.net/whatsapp-sharing/1.2.3/whatsapp-button.js";
				h.appendChild(s);
			} else {
				console.log('Desktop');
			}
		}
	); */

};

// A sharing function with FB.ui, as opposed to using the more wacky sharer.php and playing
// "guess what will be shared today"
var share_facebook = function(share_link) {
	FB.ui({
			method: 'share_open_graph',
			action_type: 'og.likes',
			action_properties: JSON.stringify({
    		object: share_link,
		})
	}, function(response) {});
};

/* What is this for?! */
/* Ah yes. Cough. Oops. This is an event listener for the Engaging Networks forum which
looks at whether the form has been successfully submitted. */
// Documentation for postMessage here: http://davidwalsh.name/window-iframe
function displayMessage (evt) {
	var message;
	if (evt.origin !== "https://www.amnesty.org.uk") {
		console.log(evt);
	}
	else {
		$('#action-slides').superslides('animate', 'next');
	}
}

jQuery(function($) {
	if (window.addEventListener) {
		// For standards-compliant web browsers
		window.addEventListener("message", displayMessage, false);
	} else {
		window.attachEvent("onmessage", displayMessage);
	}
});
