/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
*
* Google CDN, Latest jQuery
* To use the default WordPress version of jQuery, go to lib/config.php and
* remove or comment out: add_theme_support('jquery-cdn');
* ======================================================================== */

(function($) {

    // This is a fix that stops IE9 being broken by console logs
    // Source: http://stackoverflow.com/questions/10961430/ie9-not-running-javascript-onload
    if(!window.console){
        console={};
        console.log = function(){};

        // If it's not got console, it's likely to be IE9, so we'll also add that to the <html> tag, which
        // will mean we can style to it.
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // Wrap iFrames to make YouTube videos responsive
                $("p iframe").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
                $("p iframe").addClass('embed-responsive-item');

                $('.front-page-slide__arrow').on('click', function(event) {
                    event.preventDefault();
                    var scrollLocation = $(this).attr('href');
                    $('html, body').animate({
                         scrollTop: $(scrollLocation).offset().top
                    }, 500);
                });
            }
        },
        // Home page
        'home': {
            init: function() {


                // Load the EN API and start up the thermometer
                // https://github.com/davecranwell/e-Activist-social-proof-plugins
                $('.thermometer').eActivistThermometer({
                    token:'78a3a0fa-5de8-4b8f-9706-ad32c42143a4',
                    campaignId: 37080,
                    target: $('.thermometer').data('target')
                });
            },
            finalize: function() {


                //waShBtn();

                if( screen.width <= 780 ) {
                    call_whatsapp();
                }


            }
        },
        // Further action page
        'further_action': {
            init: function() {

                var borderWidth = parseInt($("#order-action-kit").css("border-top-width"));


            },
            finalize: function() {
                // This is what we want to do once the FB SDK is loaded. This MUST come before call_facebook()
                // to ensure that the init functions run
                window.fbAsyncInit = function() {
                    FB.init({
                        //appId   : '793702044047824', // Test app
                        // appId : '799736676777694', // David local test app
                        // appId : '799747496776612', // Staging
                        appId      : '788838304534198', // Live app - always put back to this before compiling!
                        //xfbml      : true,
                        version    : 'v2.3',
                        status: true
                    });
                    FB.api(
                        "/",
                        {
                            "id": "http://alchemy.local"
                        },
                        function (response) {
                            var fbShares = 0;
                            if (response && !response.error && typeof(response.shares) === 'number') {
                                fbShares = response.shares;
                            }
                            $('.social__facebook .front-page__social-media-count').html(fbShares);
                        }
                    );

                };


                // JavaScript to be fired on the home page, after the init JS
                // This is where we want to be loading our social media APIs - everything else is loaded, the ship is running
                // nicely and we can finally afford for them to start clogging things up!

                call_facebook();
                call_twitter();
                call_google();
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        'post_type_archive_casestudies': {
            init: function() {
                var windowHeight = $(window).height();

                if($(window).width() > 767) {
                    $('.type-casestudies').height(windowHeight);
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(document).ready(function() {


        // This wee little check sees if SVG is enabled in the browser. If it isn't, it changes
        // inline .svg files to be inline .png files instead.
        if(!Modernizr.svg) {
            jQuery('img[src*="svg"]').attr('src', function() {
                return jQuery(this).attr('src').replace('.svg', '.png');
            });
        }

        $(".navbar-toggle").on("click", function () {
            $(this).toggleClass("active");
        });

    });

})(jQuery); // Fully reference jQuery after this point.
